import { InjectedConnector } from '@web3-react/injected-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import configuration from "react-global-configuration";

const POLLING_INTERVAL = 12000

// export const MetaMask = new InjectedConnector({ supportedChainIds : [4]})

export const useMetaMaskConnector = () => {

  const netID = configuration.get("configData.network_id")
  ? Number(configuration.get("configData.network_id"))
  : 111;

  const MetaMask =  new InjectedConnector({ supportedChainIds : [netID]})

  return {MetaMask}

}

// export const walletconnect = new WalletConnectConnector({
//   rpc: { 1: process.env.RPC_URL_4 },
//   qrcode: true,
//   bridge: "https://bridge.walletconnect.org",
//   pollingInterval: POLLING_INTERVAL
// })


//velas mainnet

export const walletconnect = new WalletConnectConnector({
  rpc: {
      106: "https://api.velas.com",
  },
  chainId : 106 ,
  qrcode: true,
  pollingInterval: POLLING_INTERVAL
});
// walletconnect.networkId = 56;