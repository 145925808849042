import React, { useState, useEffect, useContext, useRef } from "react";
import { Button, Card, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { nftFavouritesSaveStart } from "../store/actions/NftAction";
import { authContext } from "../components/authprovider/AuthProvider";
import CustomLazyLoad from "./CustomLazyLoad";
import { useLocation } from "react-router-dom";
import usePriceImage, {
  networkLogo, stableTokenLight
} from "../components/priceImages/PriceImage";
import useStableTokenPriceImage from "../components/priceImages/PriceImage";
import { useTheme } from "@material-ui/core";
import configuration from "react-global-configuration";
import CustomTooltip from "./CustomTooltip";
import { useLayoutEffect } from "react";

const CollectionCard = (props) => {
  const { data, gridView } = props;

  const location = useLocation();

  const theme = useTheme();

  const priceImage = usePriceImage(theme.palette.theme);

  const { auth, loginConnectors, handleConnector, price } =
    useContext(authContext);

  const [favouritesCount, setFavouritesCount] = useState(data.favourites_count);

  const [favouriteStatus, setFavouriteStatus] = useState(
    data.is_favourite_item ? true : false
  );

  const chainName = configuration.get("configData.native_currency_name")
    ? configuration.get("configData.native_currency_name")
    : "collection";

  useEffect(() => {
    if (
      !props.favouriteSave.loading &&
      Object.keys(props.favouriteSave.data).length > 0
    ) {
      if (
        props.favouriteSave.data.data.token_id == data.token_id &&
        props.favouriteSave.data.data.nft_collection_id ==
          data.nft_collection_id
      ) {
        if (props.favouriteSave.data.code == "114") {
          setFavouritesCount(favouritesCount + 1);
          setFavouriteStatus(true);
        } else {
          setFavouritesCount(favouritesCount - 1 < 0 ? 0 : favouritesCount - 1);
          setFavouriteStatus(false);
        }
      }
    }
  }, [props.favouriteSave.data]);

  const handleLike = (
    contract_address,
    token_id,
    recent_owner_wallet_address
  ) => {
    props.dispatch(
      nftFavouritesSaveStart({
        nft_collection_contract_address: contract_address,
        nft_collection_item_token_id: token_id,
        recent_owner_wallet_address: recent_owner_wallet_address,
      })
    );
  };

  useEffect(() => {
    setFavouritesCount(data.favourites_count);
  }, [location]);

  const cardRef = useRef(null);

  const [imageHeight, setImageHeight] = useState(null);

  useLayoutEffect(() => {
    setImageHeight(cardRef.current.offsetWidth);
  }, [gridView]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleResize = () => {
    const delayDebounceFn = setTimeout(() => {
      setImageHeight(cardRef.current.offsetWidth);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  };

  return (
    <>
      <div
        // className={`col-12 col-md-6 ${ props.gridView ?
        //   props.gridView == 1 ? "col-lg-6 col-xl-3" : "col-lg-2" : "col-lg-4 col-xl-3 col-md-6"
        // }`}
        className={props.useFlex ? "col-lg-4 col-xl-3 col-md-6" : ""}
      >
        <div className="collection-card" ref={cardRef}>
          <Card>
            <Link
              to={`/assets/${chainName}/${data.nft_collection.contract_address}/${data.token_id}`}
            >
              <div
                className="image-wrapper"
                style={{ width: imageHeight, height: imageHeight }}
              >
                <CustomLazyLoad
                  src={
                    data.picture != ""
                      ? data.picture
                      : configuration.get("configData.item_placholder")
                  }
                  classes={"image"}
                  placeholderSrc={
                    window.location.origin +
                    "/assets/images/lazyloader-transparent.gif"
                  }
                />
              </div>
              {/* <Card.Img variant="top" src={data.picture} /> */}
              <Card.Body>
                <Card.Title>
                  <div className="card-details">
                    <div className="card-para">
                      <CustomTooltip
                        title={data.nft_collection.name}
                        placement="top"
                      >
                        <div className="verified-icon-sec">
                          <h4>{data.nft_collection.name}</h4>
                          {data.nft_collection.is_verified == 1 && (
                            <img 
                              className="verified-icon" 
                              src={window.location.origin + "/assets/images/icons/verified.png"} 
                              alt="Verified Icon" />
                          )}
                        </div>
                      </CustomTooltip>
                      {data.name != "" ? (
                        <>
                          <p className="collection-name mt-2">{data.name}</p>
                        </>
                      ) : (
                        <p className="empty-para "></p>
                      )}
                    </div>
                    {Number(data.amount) > 0 &&
                      data.is_listed == 1 &&
                      data.is_listing_started == 1 && (
                        <div className="image-price column end">
                          <p>Price</p>
                          {data.list_amount_type == 1 ? (
                            <>
                              <div className="etherum-img">
                                <img src={priceImage} className="price-image" />{" "}
                                <span>
                                  {Number(
                                    data.amount
                                  ).toLocaleString(undefined, {
                                    maximumFractionDigits: 2,
                                  })}
                                </span>
                              </div>

                              {Number(data.amount) > 0 &&
                                price.usdConvertedPrice != null &&
                                data.is_listed == 1 && (
                                  <p className="converted_price right">
                                    ≉{" "}
                                    <i className="fa fa-usd" aria-hidden="true"></i>
                                    {Number(
                                      data.amount * price.usdConvertedPrice
                                    ).toLocaleString(undefined, {
                                      maximumFractionDigits: 2,
                                    })}{" "}
                                    {price.denomination}
                                  </p>
                                )}
                            </>
                          ) : (
                            <>
                              <div className="etherum-img">
                                <img src={stableTokenLight} className="price-image" />{" "}
                                <span>
                                  {Number(
                                    data.stable_token_amount
                                  ).toLocaleString(undefined, {
                                    maximumFractionDigits: 2,
                                  })}
                                </span>
                              </div>
                            </>
                          )}
                          
                        </div>
                      )}
                  </div>
                </Card.Title>
              </Card.Body>
            </Link>
            <Card.Footer className="collection-card-footer">
              <div className="card-header-icon">
                <div
                  className={`chain-buynow-wrapper ${
                    Number(data.amount) > 0 && data.is_listed == 1
                      ? "listed"
                      : ""
                  }`}
                >
                  <CustomTooltip
                    title={configuration.get("configData.chain_name")}
                    placement="top"
                  >
                    <img src={priceImage} />
                  </CustomTooltip>
                  {data.user_id != auth.userId &&
                    Number(data.amount) > 0 &&
                    data.is_listed == 1 && (
                      <Link
                        to={`/assets/${chainName}/${data.nft_collection.contract_address}/${data.token_id}`}
                        className="buynow-text"
                      >
                        Buy now
                      </Link>
                    )}
                </div>

                <div
                  className="cursor-pointer"
                  onClick={
                    auth.authStatus
                      ? () =>
                          handleLike(
                            data.nft_collection.contract_address,
                            data.token_id,
                            data.owner_of
                              ? data.owner_of
                              : data.user
                              ? data.user.wallet_address
                              : ""
                          )
                      : () =>
                          handleConnector(
                            loginConnectors.find(
                              (connectors) => connectors.name == "MetaMask"
                            ).connectorFunction
                          )
                  }
                >
                  {favouriteStatus ? (
                    <i className="fa fa-heart mx-2"></i>
                  ) : (
                    <i className="far fa-heart mx-2"></i>
                  )}
                  {favouritesCount}
                </div>
              </div>
            </Card.Footer>
          </Card>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  favouriteSave: state.nfts.favouriteSave,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(CollectionCard);
