import React from "react";
import { Row, Col, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";


const MyCollectionCard = (props) => {
  const { data } = props;

  return (
    <>
      <Col md={6} lg={4}>
        <div className="collection-card-wrapper">
          <Link to={`/assets/collection/${data.contract_address}`}>
            <div className="profile-banner-section explore-card">
              <div className="profile-cover-image ">
                <img src={data.cover} alt="" />
              </div>
              <div className="user-details">
                <div className="profile-image explore-img">
                  <img src={data.picture} alt="" />
                </div>
                <div className="explore-detail text-center">
                  <h4>{data.name}</h4>
                  <p>
                    by <Link to="/profile">you</Link>
                  </p>
                  <p>{data.total_items} items</p>
                </div>
              </div>
            </div>
          </Link>
          {data.collection_type != 3 && (
            <>
              <div className="card-head-icon top-head-icons">
                <button className="btn-header btn-dropdown-icon">
                  <Dropdown drop={"start"}>
                    <Dropdown.Toggle variant="link" id="dropdown-basic">
                      <i className="fas fa-ellipsis-v"></i>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item>
                        <Link to={`/my-collection/${data.contract_address}`}>
                          <i className="fas fa-edit"></i>
                          Edit
                        </Link>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </button>
              </div>
            </>
          )}
        </div>
      </Col>
    </>
  );
};

export default MyCollectionCard;
