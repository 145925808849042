import React, { useState, useContext, useEffect } from "react";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import CloseIcon from "@material-ui/icons/Close";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { transferNFTStart } from "../../store/actions/NftAction";
import { authContext } from "../authprovider/AuthProvider";
import NFTMainContract from "../../abis/NftToken.json";
import { getErrorNotificationMessage } from "../helper/ToastNotification";
import { createNotification } from "react-redux-notify";
import configuration from "react-global-configuration";
import CustomAccordion from "../../customComponents/CustomAccordion";
import { updateSignAndApproveStart } from "../../store/actions/UserAction";

const TransferModal = (props) => {
  const { auth, getProviderSigner } = useContext(authContext);

  const [loadingButtonContent, setLoadingButtonContent] = useState("");

  const netID = configuration.get("configData.network_id")
    ? configuration.get("configData.network_id")
    : "97";

  const transferSchema = Yup.object().shape({
    address: Yup.string().required("Wallet address is required"),
  });

  const handleSubmit = (values) => {
    if (auth.authStatus) {
      transferNft(values.address);
    }
  };

  const getSign = async () => {
    props.setSignData({
      ...props.signData,
      loading: true,
      loadingContent: "Signing...",
    });
    if (auth.authStatus) {
      const txMessage = `
        Welcome to ${configuration.get("configData.site_name")}!
  
        Click to sign in and accept the ${configuration.get(
          "configData.site_name"
        )} Terms of Service: ${configuration.get("configData.frontend_url")}
  
        This request will not trigger a blockchain transaction or cost any gas fees.
  
        Your authentication status will reset after 24 hours.
  
        Wallet address:
        ${auth.accounts}`;
      let result = await getProviderSigner(txMessage);
      if (result) {
        props.dispatch(
          updateSignAndApproveStart({
            is_signed: 1,
            sign_signature: result,
          })
        );
        props.setSignData({
          ...props.signData,
          signature: result,
          status: true,
          loading: false,
          loadingContent: null,
        });

        props.toggleAccordion("2");
      } else {
        props.setSignData({
          ...props.signData,
          loading: false,
          loadingContent: null,
        });
      }
    } else {
      props.setSignData({
        ...props.signData,
        loading: false,
        loadingContent: null,
      });
    }
  };

  const transferNft = async (to_address) => {
    // NFT contract.
    setLoadingButtonContent("Initiated...");
    const web3 = window.web3;
    let nftContractData;
    const NFTData = NFTMainContract.networks[netID];
    if (NFTData) {
      try {
        setLoadingButtonContent((prevState) => "Transfering...");
        nftContractData = await new web3.eth.Contract(
          NFTMainContract.abi,
          props.singleNftImage.data.nft_collection.contract_address
        );
        let tokenID = Number(
          props.singleNftImage.data.nft_collection_item.token_id
        );
        nftContractData.methods
          .transferFrom(auth.accounts, to_address, tokenID)
          .send({ from: auth.accounts })
          .on("receipt", (receipt) => {
            props.dispatch(
              transferNFTStart({
                nft_collection_contract_address:
                  props.singleNftImage.data.nft_collection.contract_address,
                nft_collection_item_token_id:
                  props.singleNftImage.data.nft_collection_item.token_id,
                transaction_hash:
                  receipt && receipt.transactionHash
                    ? receipt.transactionHash
                    : "",
                is_minted: 1,
                buyer_wallet_address: to_address,
              })
            );
            setLoadingButtonContent("");
            // Send success notification.
          })
          .on("error", (error) => {
            let notificationMessage;
            if (error.message == undefined) {
              notificationMessage =
                "Unexpected error occuried, Please try again...";
            } else {
              notificationMessage = error.message;
            }
            setLoadingButtonContent("");
            props.dispatch(createNotification(notificationMessage));
          });
      } catch (error) {
        setLoadingButtonContent("");
        // Send fail notification.
        const notificationMessage = getErrorNotificationMessage(
          "Something went wrong. Please refresh the page and try again."
        );
        props.dispatch(createNotification(notificationMessage));
        console.log(error);
      }
    } else {
      setLoadingButtonContent("");
      window.alert("NFT contract not deployed to detected network.");
    }
  };

  return (
    <>
      <Modal
        show={props.open}
        onHide={props.handleClose}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        className="custom-modal transfer-modal"
        centered
      >
        <Modal.Header>
          <div className="modal-header-wrapper">
            <h3 className="mb-0 modal-heading-text">Transfer Nft</h3>
            <div className="custom-close" onClick={() => props.handleClose()}>
              <CloseIcon />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="transfer-modal-info-sec collection-form">
            {props.singleNftImage.data.nft_collection_item.is_user_signed ==
            0 ? (
              <>
                {props.signData.status ? (
                  <Formik
                    initialValues={{
                      address: "",
                    }}
                    className="w-100"
                    validationSchema={transferSchema}
                    onSubmit={(values) => handleSubmit(values)}
                    enableReinitialize
                  >
                    {({ errors, touched }) => (
                      <Form>
                        <div className="col-lg-12 col-md-12">
                          <div class="form-group ">
                            <h5>
                              Address <span class="text-danger">*</span>
                            </h5>
                            <div class="controls">
                              <Field
                                type="text"
                                name="address"
                                placeholder="Wallet Address"
                                className={`form-control ${
                                  touched.address && errors.address
                                    ? "is-invalid"
                                    : ""
                                }`}
                              />
                              <ErrorMessage
                                component="div"
                                name="address"
                                className="invalid-feedback mt-3"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="text-center">
                          <button
                            type="submit"
                            className="default-btn border-radius-5"
                            disabled={loadingButtonContent != ""}
                          >
                            {loadingButtonContent == ""
                              ? "Transfer"
                              : loadingButtonContent}
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                ) : (
                  <CustomAccordion
                    activeId={props.activeAccordion}
                    accordionHeading={"Sign message"}
                    accordionKey={"1"}
                    accordionContent={
                      "Submit a transaction with your wallet to trade with this currency. This only needs to be done once."
                    }
                    accordionButton={true}
                    OnClick={() => getSign()}
                    accordionButtonDisable={
                      props.signData.loading ||
                      props.signData.loadingContent != null
                    }
                    accordionButtonContent={
                      props.signData.loadingContent != null
                        ? props.signData.loadingContent
                        : "Sign"
                    }
                    spinner
                    spinnerLoading={
                      props.signData.loadingContent != null &&
                      props.activeAccordion === "1"
                    }
                    activeAccordion={props.activeAccordion}
                    loadingComplete={
                      Number(props.activeAccordion) > Number("1")
                    }
                  />
                )}
              </>
            ) : (
              <Formik
                initialValues={{
                  address: "",
                }}
                className="w-100"
                validationSchema={transferSchema}
                onSubmit={(values) => handleSubmit(values)}
                enableReinitialize
              >
                {({ errors, touched }) => (
                  <Form>
                    <div className="col-lg-12 col-md-12">
                      <div class="form-group ">
                        <h5>
                          Address <span class="text-danger">*</span>
                        </h5>
                        <div class="controls">
                          <Field
                            type="text"
                            name="address"
                            placeholder="Wallet Address"
                            className={`form-control ${
                              touched.address && errors.address
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                          <ErrorMessage
                            component="div"
                            name="address"
                            className="invalid-feedback mt-3"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="text-center">
                      <button
                        type="submit"
                        className="default-btn border-radius-5"
                        disabled={loadingButtonContent != ""}
                      >
                        {loadingButtonContent == ""
                          ? "Transfer"
                          : loadingButtonContent}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const mapStateToPros = (state) => ({
  transferNft: state.nfts.transferNft,
  profile: state.users.profile,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(React.memo(TransferModal));
