import React, { useState, useEffect, useContext } from "react";
import SearchIcon from "@material-ui/icons/Search";
import { connect } from "react-redux";
import { nftSearchStart , clearSearchData} from "../store/actions/NftAction";
import { makeStyles } from "@material-ui/core/styles";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { authContext } from "../components/authprovider/AuthProvider";
import configuration from "react-global-configuration";

const styles = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
  dropdown: {
    
    position: (props) => (props.classes == "mobile-search" ? "relative" : "absolute"),
    // top: 28,
    right: (props) => (props.classes == "mobile-search" ? "0" : 0),
    left: (props) => (props.classes == "mobile-search" ? "0" : 0),
    zIndex: 1,
    // border: `1px solid ${theme.palette.divider}`,
    borderRadius: "5px",
    backgroundColor: theme.palette.background.paper,
    padding: "0.5rem 0",
    boxShadow: (props) => (props.classes == "mobile-search" ? "none" : "rgb(0 0 0 / 16%) 0px 4px 16px") ,
    maxHeight: "calc(100vh - 70px - 1rem)",
  },
}));

const CustomSearch = (props) => {

  const chainName = configuration.get("configData.native_currency_name")
  ? configuration.get("configData.native_currency_name")
  : "collection";

  const location = useLocation();

  const { auth } = useContext(authContext);

  const [searchKey, setSearchKey] = useState("");

  const onChange = (value) => {
    setSearchKey(value);
  };

  useEffect(() => {
    if (searchKey.length > 0) {
      const delayDebounceFn = setTimeout(() => {
        props.dispatch(nftSearchStart({ search_key: searchKey }));
      }, 1000);

      return () => clearTimeout(delayDebounceFn);
    }else{
      props.dispatch(clearSearchData())
    }
  }, [searchKey]);

  const classes = styles({classes : props.classes});

  const handleClickAway = () => {
    setSearchKey("");
  };

  useEffect(() => {
    setSearchKey("");
  }, [location]);

  return (
    <>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div className={`nav-widget-form ${props.classes}`}>
          <form className="search-form">
            <div className="search-icon">
              <SearchIcon />
            </div>
            <input
              value={searchKey}
              type="input"
              className="form-control"
              placeholder="Search Items, Collections and Accounts"
              onChange={(event) => onChange(event.target.value)}
            />
            {searchKey != "" && (
              <div className="search-clear">
                <i className="fas fa-times" onClick={() => onChange("")}></i>
              </div>
            )}
          </form>
          {searchKey != "" &&
            (props.nftSearch.loading ? (
              <>
                <div className={classes.dropdown}>
                  <ul className="header-search">
                    <li className="search-list">
                      <div className="text-center py-3">
                        <Spinner
                          animation="border"
                          className="custom-spinner"
                          size="sm"
                          variant="primary"
                        />
                      </div>
                    </li>
                  </ul>
                </div>
              </>
            ) : props.nftSearch.data.total > 0 ? (
              <div className={classes.dropdown}>
                <ul className="header-search">
                  {props.nftSearch.data.nft_collections.length > 0 ? (
                    <>
                      <li className="search-list search-title">Collections</li>
                      {props.nftSearch.data.nft_collections.map(
                        (nft_collection) => (
                          <li className="search-list">
                            {nft_collection.user_id === auth.userId ? (
                              <Link
                                to={`/assets/collection/${nft_collection.contract_address}`}
                              >
                                <div className="search-text">
                                  <img src={nft_collection.picture} />
                                  <div className="verified-icon-sec justify-center">
                                    <p className="mb-0">{nft_collection.name}</p>
                                    {nft_collection.is_verified == 1 && (
                                      <img 
                                        className="verified-icon" 
                                        src={window.location.origin + "/assets/images/icons/verified.png"} 
                                        alt="Verified Icon" />
                                    )}
                                  </div>
                                  <p className="collection-item-count">
                                    {nft_collection.total_items} Items
                                  </p>
                                </div>
                              </Link>
                            ) : (
                              <Link
                                to={`/collection/${nft_collection.contract_address}`}
                              >
                                <div className="search-text">
                                  <img src={nft_collection.picture} />
                                  <div className="verified-icon-sec justify-center">
                                    <p className="mb-0">{nft_collection.name}</p>
                                    {nft_collection.is_verified == 1 && (
                                      <img 
                                        className="verified-icon" 
                                        src={window.location.origin + "/assets/images/icons/verified.png"} 
                                        alt="Verified Icon" />
                                    )}
                                  </div>
                                  <p className="collection-item-count">
                                    {nft_collection.total_items} Items
                                  </p>
                                </div>
                              </Link>
                            )}
                          </li>
                        )
                      )}
                    </>
                  ) : null}
                  {props.nftSearch.data.nft_collection_items.length > 0 ? (
                    <>
                      <li className="search-list search-title">Items</li>
                      {props.nftSearch.data.nft_collection_items.map((item) => (
                        <li className="search-list">
                          <Link
                            to={`/assets/${chainName}/${item.nft_collection.contract_address}/${item.token_id}`}
                          >
                            <div className="search-text">
                              <img src={item.picture} />
                              <p>{item.name}</p>
                            </div>
                          </Link>
                        </li>
                      ))}
                    </>
                  ) : null}
                  {props.nftSearch.data.users.length > 0 ? (
                    <>
                      <li className="search-list search-title">Accounts</li>
                      {props.nftSearch.data.users.map((user) => (
                        <li className="search-list">
                          {user.user_unique_id == auth.userUniqueId ? (
                            <Link to={`/profile`}>
                              <div className="search-text">
                                <img src={user.picture} />
                                <p>{user.name}</p>
                              </div>
                            </Link>
                          ) : (
                            <Link to={`/account/${user.user_unique_id}`}>
                              <div className="search-text">
                                <img src={user.picture} />
                                <p>{user.name}</p>
                              </div>
                            </Link>
                          )}
                        </li>
                      ))}
                    </>
                  ) : null}
                </ul>
              </div>
            ) : (
              <div className={classes.dropdown}>
                <ul className="header-search">
                  <li className="search-list">
                    <div className="search-text p-3 text-center">
                      <p className="w-100">No data found</p>
                    </div>
                  </li>
                </ul>
              </div>
            ))}
        </div>
      </ClickAwayListener>
    </>
  );
};

const mapStateToPros = (state) => ({
  categories: state.nfts.categoriesList,
  nftSearch: state.nfts.nftSearch,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(CustomSearch);
