import React, { useState, useEffect, useContext, useLayoutEffect } from "react";
import { Link } from "react-router-dom";
import CustomSlider from "../../customComponents/CustomSlider";
import { connect } from "react-redux";
import configuration from "react-global-configuration";
import {
  fetchTrendingNftItemsStart,
  fetchNftCollectionsListForOthersStart,
} from "../../store/actions/NftAction";
import CustomTooltip from "../../customComponents/CustomTooltip";
import CollectionCard from "../../customComponents/CollectionCard";
import CollectionCardLoader from "../loaders/CollectionCardLoader";
import TopSellersLoader from "../loaders/TopSellersLoader";
import { Helmet } from "react-helmet";
import MetaTags from "react-meta-tags";

const LandingPage = (props) => {
  const [sortByDays, setSortByDays] = useState(7);

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    props.dispatch(fetchTrendingNftItemsStart());
    props.dispatch(
      fetchNftCollectionsListForOthersStart({ sort_by_days: sortByDays })
    );
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleResize = () => {
    setWidth(window.innerWidth);
  };

  return (
    <>
      <div className="banner-area pages-padding">
        <div
          className="banner-cover-img"
          style={{
            backgroundImage: `url(${configuration.get(
              "configData.banner_cover"
            )})`,
          }}
        ></div>
        <div className="container pt-4">
          <div className="row align-items-center">
            <div className="col-12 col-md-12  col-lg-6 mx-auto">
              <div className="banner-content">
                <p
                  dangerouslySetInnerHTML={{
                    __html: configuration.get("configData.banner_content"),
                  }}
                ></p>
                <div className="banner-btn">
                  <Link to={"/explore"} className="default-btn border-radius-5">
                    Explore More
                  </Link>
                  {/* <Link
                    to={"/create-new-item"}
                    className="default-btn two border-radius-5 "
                  >
                    Create
                  </Link> */}
                </div>
              </div>
            </div>

            <div className="col-12 col-md-12 col-lg-5 mx-auto">
              <Link
                to={configuration.get("configData.banner_link")}
                className="banner-card-area"
              >
                <div className="banner-card banner-card-mt">
                  <div className="banner-card-img">
                    <img
                      src={configuration.get("configData.banner_cover")}
                      alt="Images"
                    />
                  </div>

                  <div className="content">
                    <div className="banner-user-list">
                      <div className="banner-user-list-img">
                        <img
                          src={configuration.get("configData.banner_picture")}
                          alt="Images"
                        />
                        {/* <i className="ri-check-line"></i> */}
                      </div>
                      {configuration.get("configData.banner_profile_content")}
                    </div>
                    <h4 className="banner-user-btn mb-0">
                      <i className="ri-arrow-right-line"></i>
                    </h4>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      {props.trendingItems.loading ? (
        <>
          <div className="container  pt-100 ">
            <div className="trending-area-loader">
              <CollectionCardLoader
                count={width < 768 ? 1 : width < 1200 ? 2 : 3}
              />
            </div>
          </div>
        </>
      ) : props.trendingItems.data.nft_collection_items.length > 0 ? (
        <div className="trending-area pt-100 ">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="section-title text-center">
                  <h2>Trending Artwork</h2>
                </div>
              </div>
            </div>
            <CustomSlider
              settings={{
                dots: true,
                infinite: false,
                speed: 500,
                slidesToShow: 3,
                slidesToScroll: 1,
                focusOnSelect: false,
                arrows: true,
                lazyLoad: `progressive`,
              }}
              className="trending-slider owl-carousel owl-theme pt-45"
            >
              {props.trendingItems.data.nft_collection_items.sort((prev, next) => parseFloat(next.favourites_count) - parseFloat(prev.favourites_count)).map(
                (item, index) => (
                  <CollectionCard data={item} useFlex={false} key={index} />
                )
              )}
            </CustomSlider>
          </div>
        </div>
      ) : null}
      {props.collections.loading ? (
        <div className="container pt-100">
          <div className="top-sellers-area-loader">
            <TopSellersLoader count={12} classes={"col-xl-4 col-lg-6 col-12"} />
          </div>
        </div>
      ) : props.collections.data.nft_collections.length > 0 ? (
        <div className="top-sellers-area pt-100">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="section-title text-center">
                  <h2>
                    Top collections over{" "}
                    <span className="sec-span">last 7 days</span>
                  </h2>
                </div>
              </div>
            </div>

            <div className="row pt-45">
              {props.collections.data.nft_collections.map(
                (collection, index) => (
                  <div className="col-xl-4 col-lg-6 col-12" key={index}>
                    <Link
                      to={`/collection/${collection.contract_address}`}
                      className="top-sellers-item"
                    >
                      <div className="number">
                        <h5 className="mb-0">{index + 1}</h5>
                      </div>
                      <div className="top-sellers-img">
                        <img src={collection.picture} alt="Images" />
                        {collection.is_verified == 1 && (
                          <img 
                            className="verified-icon" 
                            src={window.location.origin + "/assets/images/icons/verified.png"} 
                            alt="Verified Icon" />
                        )}
                          {/* <i className="ri-check-line"></i> */}
                      </div>
                      <div className="content">
                        <CustomTooltip title={collection.name} placement="top">
                          <div className="verified-icon-sec">
                            <h5>{collection.name}</h5>
                          </div>
                        </CustomTooltip>
                        <p>{collection.total_items} items</p>
                      </div>
                      {/* <div className="profit-number">
                          <h5 className="mb-0">+15%</h5>
                        </div> */}
                    </Link>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      ) : null}

      <div className="create-area pt-100">
        <div className="container">
          <div className="section-title text-center">
            <h2>Create And Sell Your NFTs</h2>
          </div>

          <div className="row align-items-center pt-45">
            <div className="col-lg-6">
              <div className="create-img nft-image">
                <img
                  src={window.location.origin + "/assets/images/nft-image.jpg"}
                  alt="Images"
                />
              </div>
            </div>

            <div className="col-lg-6">
              <div className="create-card-right pl-20">
                <div className="row justify-content-center">
                  <div className="col-lg-6 col-6">
                    <div className="create-card text-center icon-nft">
                      <img
                        src={
                          window.location.origin +
                          "/assets/images/icons/wallet.png"
                        }
                        alt="Images"
                      />
                      <h3>Set Up Your Wallet</h3>
                      <p>
                        connect wallet by clicking the wallet icon in the top
                        right corner. Learn about the wallets we support.
                      </p>
                    </div>
                  </div>

                  <div className="col-lg-6 col-6">
                    <div className="create-card text-center icon-nft">
                      <img
                        src={
                          window.location.origin +
                          "/assets/images/icons/collection.png"
                        }
                        alt="Images"
                      />
                      <h3>Create Your Collection</h3>
                      <p>
                        Click Create and Add social links, a description,
                        profile & banner images, and set a secondary sales fee.
                      </p>
                    </div>
                  </div>

                  <div className="col-lg-6 col-6 text-center icon-nft">
                    <div className="create-card">
                      <img
                        src={
                          window.location.origin +
                          "/assets/images/icons/addnft.png"
                        }
                        alt="Images"
                      />
                      <h3>Add Your NFTs</h3>
                      <p>
                        Upload your work (image,3D art,GIF), add a
                        title and description, and customize your NFTs
                      </p>
                    </div>
                  </div>

                  <div className="col-lg-6 col-6 text-center icon-nft">
                    <div className="create-card">
                      <img
                        src={
                          window.location.origin +
                          "/assets/images/icons/sale.png"
                        }
                        alt="Images"
                      />
                      <h3>List Them For Sale</h3>
                      <p>
                        Choose between auctions, fixed-price listings, and
                        declining-price listings. You choose how you want to
                        sell your NFTs
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  trendingItems: state.nfts.trendingItems,
  collections: state.nfts.collectionListForOthers,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(LandingPage);
