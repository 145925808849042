import React, {
  useLayoutEffect,
  useRef,
  useState,
  useEffect,
  useContext,
} from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import SwapHoriz from "@material-ui/icons/SwapHoriz";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import CancelIcon from "@material-ui/icons/Cancel";
import { Link } from "react-router-dom";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import usePriceImage, {
  networkLogo, stableTokenLight
} from "../components/priceImages/PriceImage";
import configuration from "react-global-configuration";
import CustomTooltip from "../customComponents/CustomTooltip";
import { useTheme } from "@material-ui/core";
import PanToolIcon from "@material-ui/icons/PanTool";
import { Button, Collapse } from "react-bootstrap";
import { authContext } from "../components/authprovider/AuthProvider";

const tableStyles = makeStyles({
  root: {
    backgroundColor: "red",

    "& .MuiPaper-root": {
      boxShadow: "none",
    },
  },
  table: {
    "& .MuiTable-stickyHeader": {
      backgroundColor: "transparent",
      borderBottom: "none",

      "& .MuiTableHead-root": {
        borderBottom: "1px solid rgb(229, 232, 235)",

        "& .MuiTableCell-stickyHeader": {
          fontWeight: 600,
          fontSize: "1.25rem",
          backgroundColor: "transparent",
          textAlign: "center",
          borderBottom: "none",
        },
      },
    },
  },
});

const Activitytable = (props) => {
  const chainName = configuration.get("configData.native_currency_name")
    ? configuration.get("configData.native_currency_name")
    : "collection";

  const classes = tableStyles();

  const { price } = useContext(authContext);

  const { nft_collection_contract_address } = props;

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const theme = useTheme();

  const priceImage = usePriceImage(theme.palette.theme);

  const [activeShowMore, setActiveShowMore] = useState([]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  const handleShowMore = (index) => {
    const itemPresent = activeShowMore.includes(index);

    if (itemPresent) {
      const removedItem = activeShowMore.filter((items) => items != index);

      setActiveShowMore(removedItem);
    } else {
      setActiveShowMore([...activeShowMore, index]);
    }
  };

  return (
    <>
      {props.activityTable ? (
        <>
          {windowWidth <= 768 ? (
            <>
              <div className="mobile-activity-table-wrapper">
                {props.paymentData.map((payment, index) => (
                  <>
                    <div className="mobile-activity-list" key={index}>
                      <div className="contents-wrapper">
                        <div className="activity-list-image-wrapper">
                          <Link
                            className="item-details"
                            to={`/assets/${chainName}/${nft_collection_contract_address}/${payment.nft_collection_item_token_id}`}
                          >
                            <img
                              src={payment.nft_collection_item_picture}
                              alt=""
                            />
                          </Link>
                          <div className="item-info">
                            <Link
                              className="item-details"
                              to={`/assets/${chainName}/${nft_collection_contract_address}/${payment.nft_collection_item_token_id}`}
                            >
                              <h6 className="mb-0">
                                {payment.nft_collection_item_name}{" "}
                              </h6>
                            </Link>
                            <button
                              className="no-btn withicon"
                              onClick={() => handleShowMore(index)}
                            >
                              {activeShowMore.includes(index) ? (
                                <>
                                  <i class="fas fa-minus"></i> Less
                                </>
                              ) : (
                                <>
                                  <i class="fas fa-plus"></i> More
                                </>
                              )}
                            </button>
                          </div>
                        </div>
                        <div>
                          <div>
                            {(() => {
                              if (payment.payment_type == "1") {
                                return <h6>List</h6>;
                              } else if (payment.payment_type == "2") {
                                return <h6>Sale</h6>;
                              } else if (payment.payment_type == "3") {
                                return <h6>Transfer</h6>;
                              } else if (payment.payment_type == "4") {
                                return <h6>Cancel</h6>;
                              } else if (payment.payment_type == "5") {
                                return <h6>Bid</h6>;
                              }
                            })()}
                            <div className="etherum-img mb-2">
                              {payment.list_amount_type == 1 ? (
                                <>
                                  <img src={priceImage} />
                                <span>
                                  {Number(
                                      payment.amount
                                    ).toLocaleString(undefined, {
                                      maximumFractionDigits: 2,
                                    })}
                                  </span>
                                </>
                              ) : (
                                <>
                                  <img src={stableTokenLight} />
                                  <span>
                                    {Number(
                                        payment.stable_token_amount
                                      ).toLocaleString(undefined, {
                                        maximumFractionDigits: 2,
                                      })}
                                  </span>
                                </>
                              )}
                            </div>
                          </div>
                          {Number(payment.amount) > 0 &&
                              price.usdConvertedPrice != null && (
                                <p className="converted_price right mt-2">
                                  ≉{" "}
                                  <i
                                    className="fa fa-usd"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  {Number(
                                    payment.amount * price.usdConvertedPrice
                                  ).toLocaleString(undefined, {
                                    maximumFractionDigits: 2,
                                  })}{" "}
                                  {price.denomination}
                                </p>
                              )}
                        </div>
                      </div>
                      <Collapse in={activeShowMore.includes(index)}>
                        <div className="activity-collapse-content">
                          <div className="activity-collapse-content-wrapper">
                            <div className="activity-collapse-item">
                              <h6>Price</h6>
                              <div className="etherum-img">
                                <img src={priceImage} />
                                <span>{payment.amount}</span>
                              </div>
                            </div>
                            <div className="activity-collapse-item">
                              <h6>From</h6>
                              <Link
                                to={
                                  payment.from_user.user_unique_id ===
                                  props.auth.userUniqueId
                                    ? `/profile`
                                    : `/account/${payment.from_user.user_unique_id}`
                                }
                              >
                                {payment.from_formatted}
                              </Link>
                            </div>
                            <div className="activity-collapse-item">
                              <h6>To</h6>
                              {payment.to_user ? (
                                <Link
                                  to={
                                    payment.to_user.user_unique_id ===
                                    props.auth.userUniqueId
                                      ? `/profile`
                                      : `/account/${payment.to_user.user_unique_id}`
                                  }
                                >
                                  {payment.to_formatted}
                                </Link>
                              ) : (
                                "-"
                              )}
                            </div>
                            <div className="activity-collapse-item">
                              <h6>Date</h6>
                              {configuration.get("configData.rpc_url") != "" &&
                              payment.transaction_hash != "" ? (
                                <CustomTooltip title="View" placement="top">
                                  <a
                                    href={`${configuration.get(
                                      "configData.block_explorer_urls"
                                    )}/tx/${payment.transaction_hash}`}
                                    target="_blank"
                                  >
                                    {payment.created}
                                  </a>
                                </CustomTooltip>
                              ) : (
                                <span> {payment.created}</span>
                              )}
                            </div>
                          </div>
                        </div>
                      </Collapse>
                    </div>
                  </>
                ))}
              </div>
            </>
          ) : (
            <div className={props.classes ? props.classes : "px-3 mb-3"}>
              <div className="activity-table-wrapper">
                <div className="table-header">
                  <div className="table-header-item">
                    <h5 className="table-heading"></h5>
                  </div>
                  <div className="table-header-item">
                    <h5 className="table-heading">Item</h5>
                  </div>
                  <div className="table-header-item">
                    <h5 className="table-heading">Price</h5>
                  </div>

                  <div className="table-header-item">
                    <h5 className="table-heading">From</h5>
                  </div>
                  <div className="table-header-item">
                    <h5 className="table-heading">To</h5>
                  </div>
                  <div className="table-header-item">
                    <h5 className="table-heading">Date</h5>
                  </div>
                </div>
                <div className="table-body-wrapper">
                  {props.paymentData.map((payment, index) => (
                    <>
                      <div className="table-body" key={index}>
                        <div className="table-body-item">
                          {(() => {
                            if (payment.payment_type == "1") {
                              return (
                                <LocalOfferIcon className="payment-icon" />
                              );
                            } else if (payment.payment_type == "2") {
                              return (
                                <ShoppingCartIcon className="payment-icon" />
                              );
                            } else if (payment.payment_type == "3") {
                              return <SwapHoriz className="payment-icon" />;
                            } else {
                              return <PanToolIcon className="payment-icon" />;
                            }
                          })()}
                          {payment.payment_type_formatted}
                        </div>
                        <div className="table-body-item">
                          <Link
                            className="item-details"
                            to={`/assets/${chainName}/${nft_collection_contract_address}/${payment.nft_collection_item_token_id}`}
                          >
                            <img
                              src={payment.nft_collection_item_picture}
                              alt=""
                            />
                            <div className="item-info">
                              <h6 className="mb-0">
                                {payment.nft_collection_item_name}
                              </h6>
                            </div>
                          </Link>
                        </div>
                        <div className="table-body-item">
                          <div>
                            <div className="etherum-img ">
                              {payment.list_amount_type == 1 ? (
                                <>
                                  <img src={priceImage} />
                                <span>
                                  {Number(
                                      payment.amount
                                    ).toLocaleString(undefined, {
                                      maximumFractionDigits: 2,
                                    })}
                                  </span>
                                </>
                              ) : (
                                <>
                                  <img src={stableTokenLight} />
                                  <span>
                                    {Number(
                                        payment.stable_token_amount
                                      ).toLocaleString(undefined, {
                                        maximumFractionDigits: 2,
                                      })}
                                  </span>
                                </>
                              )}
                            </div>
                            {Number(payment.amount) > 0 &&
                              price.usdConvertedPrice != null && (
                                <p className="converted_price right mt-2">
                                  ≉{" "}
                                  <i
                                    className="fa fa-usd"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  {Number(
                                    payment.amount * price.usdConvertedPrice
                                  ).toLocaleString(undefined, {
                                    maximumFractionDigits: 2,
                                  })}{" "}
                                  {price.denomination}
                                </p>
                              )}
                          </div>
                        </div>
                        <div className="table-body-item">
                          {payment.from_user ? (
                            <Link
                              to={
                                payment.from_user.user_unique_id ===
                                props.auth.userUniqueId
                                  ? `/profile`
                                  : `/account/${payment.from_user.user_unique_id}`
                              }
                            >
                              {payment.from_formatted}
                            </Link>
                          ) : null}
                        </div>
                        <div className="table-body-item">
                          {payment.to_user != null ? (
                            <Link
                              to={
                                payment.to_user.user_unique_id ===
                                props.auth.userUniqueId
                                  ? `/profile`
                                  : `/account/${payment.to_user.user_unique_id}`
                              }
                            >
                              {payment.to_formatted}
                            </Link>
                          ) : (
                            "-"
                          )}
                        </div>
                        <div className="table-body-item">
                          {configuration.get("configData.rpc_url") != "" &&
                          payment.transaction_hash != "" ? (
                            <CustomTooltip title="View" placement="top">
                              <a
                                href={`${configuration.get(
                                  "configData.block_explorer_urls"
                                )}/tx/${payment.transaction_hash}`}
                                target="_blank"
                              >
                                {payment.created}
                              </a>
                            </CustomTooltip>
                          ) : (
                            <>{payment.created}</>
                          )}
                        </div>
                      </div>
                    </>
                  ))}
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          {props.paymentData.map((payment, index) => (
            <TableRow
              key={index}
              sx={{
                "&:last-child td, &:last-child th": {
                  border: 0,
                },
              }}
            >
              <TableCell component="th" scope="row">
                {(() => {
                  if (payment.payment_type == "1") {
                    return <LocalOfferIcon className="payment-icon" />;
                  } else if (payment.payment_type == "2") {
                    return <ShoppingCartIcon className="payment-icon" />;
                  } else if (payment.payment_type == "3") {
                    return <SwapHoriz className="payment-icon" />;
                  } else {
                    return <PanToolIcon className="payment-icon" />;
                  }
                })()}
                {payment.payment_type_formatted}
              </TableCell>
              <TableCell component="th" scope="row">
                <div className="etherum-img">
                  {payment.list_amount_type == 1 ? (
                    <>
                      <img src={priceImage} />
                    <span>
                      {Number(
                          payment.amount
                        ).toLocaleString(undefined, {
                          maximumFractionDigits: 2,
                        })}
                      </span>
                    </>
                  ) : (
                    <>
                      <img src={stableTokenLight} />
                      <span>
                        {Number(
                            payment.stable_token_amount
                          ).toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                          })}
                      </span>
                    </>
                  )}
                </div>
              </TableCell>
              <TableCell component="th" scope="row">
                {payment.from_user ? (
                  <Link
                    to={
                      payment.from_user.user_unique_id ===
                      props.auth.userUniqueId
                        ? `/profile`
                        : `/account/${payment.from_user.user_unique_id}`
                    }
                  >
                    {payment.from_formatted}
                  </Link>
                ) : null}
              </TableCell>
              <TableCell component="th" scope="row">
                {payment.to_user ? (
                  <Link
                    to={
                      payment.to_user.user_unique_id === props.auth.userUniqueId
                        ? `/profile`
                        : `/account/${payment.to_user.user_unique_id}`
                    }
                  >
                    {payment.to_formatted}
                  </Link>
                ) : null}
              </TableCell>
              <TableCell component="th" scope="row">
                {configuration.get("configData.block_explorer_urls") != "" &&
                payment.transaction_hash != "" ? (
                  <CustomTooltip title="View" placement="top">
                    <a
                      href={`${configuration.get(
                        "configData.block_explorer_urls"
                      )}/tx/${payment.transaction_hash}`}
                      target="_blank"
                    >
                      {payment.created}
                    </a>
                  </CustomTooltip>
                ) : (
                  <>{payment.created}</>
                )}
              </TableCell>
              {/* <TableCell align="right">
                        {payment.transaction_hash}
                      </TableCell>
                      <TableCell align="right">
                        {payment.wallet_address}
                      </TableCell>
                      <TableCell align="right">
                        {payment.contract_address}
                      </TableCell> */}
            </TableRow>
          ))}
        </>
      )}
    </>
  );
};

export default Activitytable;
