import React, { useContext } from "react";
import CustomRangerCalender from "../../customComponents/CustomRangerCalender";
import { Form } from "react-bootstrap";
import configuration from "react-global-configuration";
import CustomSelect from "../../customComponents/CustomSelect";
import CustomTooltip from "../../customComponents/CustomTooltip";
import { authContext } from "../authprovider/AuthProvider";

const FixedPriceListing = (props) => {
  const {
    priceSelect,
    handlePriceSelect,
    priceDate,
    handlePriceDateOpen,
    setPriceDate,
    handlePriceRangeDropdown,
    priceDateRange,
    setPriceDateRange,
    handleStartTimeChange,
    handleEndTimeChange,
    handlePriceDateClose,
    handleSellDataChanges,
    handleRangeSelection,
    sellData,
  } = props;

  const { price } = useContext(authContext);

  return (
    <>
      <div className="listing-item-form">
        <Form.Group className="" controlId="formBasicEmail">
          <Form.Label>Price</Form.Label>
          <CustomSelect
            value={priceSelect}
            handleChange={handlePriceSelect}
            items={[configuration.get("configData.native_currency_symbol"), configuration.get("configData.stable_toke_symbol")]}
            placeHolder={configuration.get("configData.native_currency_symbol")}
            defaultValue={configuration.get(
              "configData.native_currency_symbol"
            )}
          />
        </Form.Group>
        <Form.Group className="align-text-right" controlId="formBasicEmail">
          <CustomTooltip
            title={
              "List price cannot be edited once the item is listed. You will need to cancel your listing and relist the item with the updated price and dates."
            }
            placement="top"
          >
            <Form.Label>
              <i className="fas fa-info-circle"></i>
            </Form.Label>
          </CustomTooltip>

          <Form.Control
            type="number"
            placeholder="0.00001"
            name="amount"
            min="0.00"
            step="any"
            onChange={(event) => handleSellDataChanges(event)}
          />
          {price.usdConvertedPrice != null && 
            priceSelect[0] == configuration.get("configData.native_currency_symbol") &&
            sellData.amount != "" &&
            Number(sellData.amount) > 0 && (
              <p className="converted_price right">
                ≉ <i className="fa fa-usd" aria-hidden="true"></i>{" "}
                {Number(
                  sellData.amount * price.usdConvertedPrice
                ).toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                })}{" "}
                {price.denomination}
              </p>
            )}
        </Form.Group>
      </div>
      <CustomRangerCalender
        priceDate={priceDate}
        handlePriceDateOpen={handlePriceDateOpen}
        setPriceDate={setPriceDate}
        handlePriceRangeDropdown={handlePriceRangeDropdown}
        popup
        priceDateRange={priceDateRange}
        setPriceDateRange={setPriceDateRange}
        handleRangeSelection={handleRangeSelection}
        handleStartTimeChange={handleStartTimeChange}
        handleEndTimeChange={handleEndTimeChange}
        handlePriceDateClose={handlePriceDateClose}
        months={2}
        placeHolder={"Duration"}
        tooltip
        tooltipContent={"Time duration for listing"}
      />
      {priceDate.isStartTimeError && (
        <p className="invalid-message-text">
          * Start date must be now or in the future
        </p>
      )}
      {priceDate.isEndTimeError && (
        <p className="invalid-message-text mt-3">
          * End time must be atleast 15 minutes from current time
        </p>
      )}
    </>
  );
};

export default React.memo(FixedPriceListing);
