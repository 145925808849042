import React, { useContext, useEffect, useRef, useState } from "react";
import { createBrowserHistory as createHistory } from "history";
import { Switch, Route, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";
import configuration from "react-global-configuration";
import { apiConstants } from "../Constant/constants";
import {
  setTranslations,
  setDefaultLanguage,
  translate,
  setLanguage,
} from "react-multi-lang";
import en from "../translations/en.json";
import es from "../translations/es.json";
import LandingPage from "../landingPage/LandingPage";
import EmptyLayout from "../../components/layouts/EmptyLayout";
import { authContext } from "../authprovider/AuthProvider";
import Collection from "../Collection/Collection";
import SingleNftImage from "../Collection/SingleNftImage";
import Profile from "../profile/Profile";
import MyCollection from "../Collection/MyCollection";
import CreateCollection from "../Collection/CreateCollection";
import CreateNewItem from "../Collection/CreateNewItem";
import ProfileSettings from "../Settings/ProfileSettings";
import Explore from "../Explore/Explore";
import ListingItemIndex from "../Listing/ListingItemIndex";
import CollectionLayout from "../layouts/CollectionLayout";
import PageNotFound from "../404/PageNotFound";
import StaticPage from "../StaticPage/StaticPage";
import AllitemsViewer from "../Collection/AllitemsViewer";
import OtherProfile from "../profile/OtherProfile";
import NoDataFound from "../NoDataFound/NoDataFound";
import EditCollection from "../Collection/EditCollection";
import EditNewitem from "../Collection/EditNewItem";
import OwnerCollectionViewer from "../Collection/OwnerCollectionViewer";
import ImportCollection from "../Collection/ImportCollection";

setTranslations({ en, es });

const history = createHistory();
const $ = window.$;

const AppRoute = ({
  component: Component,
  layout: Layout,
  screenProps: ScreenProps,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => (
      <Layout screenProps={ScreenProps} {...props} {...rest}>
        <Component {...props} />
      </Layout>
    )}
    isAuthed
  />
);

const PrivateRoute = ({
  component: Component,
  layout: Layout,
  screenProps: ScreenProps,
  authentication,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      authentication === true ? (
        <Layout screenProps={ScreenProps} {...props} {...rest}>
          <Component {...props} authRoute={true} />
        </Layout>
      ) : (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      )
    }
  />
);

const App = () => {
  let userId = localStorage.getItem("userId");
  let accessToken = localStorage.getItem("accessToken");
  let userLoginStatus = localStorage.getItem("userLoginStatus");

  const chainName = configuration.get("configData.native_currency_name")
    ? configuration.get("configData.native_currency_name")
    : "collection";

  const [loading, setLoading] = useState(true);

  const [configLoading, setConfigLoading] = useState(true);

  const [authentication, setAuthntication] = useState(
    userId && accessToken && userLoginStatus == "true" ? true : false
  );

  const { auth } = useContext(authContext);

  history.listen((location, action) => {
    userId = localStorage.getItem("userId");
    accessToken = localStorage.getItem("accessToken");
    userLoginStatus = localStorage.getItem("userLoginStatus");
    setLoading(true);
    setAuthntication(
      userId && accessToken && userLoginStatus == "true" ? true : false
    );
    document.body.scrollTop = 0;
  });

  useEffect(() => {
    setAuthntication(auth.authStatus);
  }, [auth.authStatus]);

  return (
    <>
      <Helmet>
        <title>{configuration.get("configData.site_name")}</title>
        <link
          rel="icon"
          type="image/png"
          href={configuration.get("configData.site_icon")}
          // sizes="16x16"
        />
        <script src={configuration.get("configData.head_scripts")}></script>
      </Helmet>
      <Switch>
        <PrivateRoute
          authentication={authentication}
          path={"/profile"}
          component={Profile}
          layout={CollectionLayout}
        />
        <AppRoute
          path={"/"}
          component={LandingPage}
          exact
          layout={EmptyLayout}
        />
        <AppRoute
          path={"/collection/:contract_address"}
          component={Collection}
          layout={CollectionLayout}
        />
        <AppRoute
          path={`/assets/${chainName}/:contract_address/:token_id`}
          component={SingleNftImage}
          layout={EmptyLayout}
        />
        <PrivateRoute
          authentication={authentication}
          path={"/my-collection"}
          exact
          component={MyCollection}
          layout={EmptyLayout}
        />
        <PrivateRoute
          authentication={authentication}
          path={"/create-collection"}
          component={CreateCollection}
          layout={EmptyLayout}
        />
        <PrivateRoute
          authentication={authentication}
          path={"/create-new-item"}
          component={CreateNewItem}
          layout={EmptyLayout}
        />
        <PrivateRoute
          authentication={authentication}
          path={"/profile-setting"}
          component={ProfileSettings}
          layout={CollectionLayout}
        />

        <PrivateRoute
          authentication={authentication}
          path={"/import-collection"}
          component={ImportCollection}
          layout={EmptyLayout}
        />

        <AppRoute path={"/explore"} component={Explore} layout={EmptyLayout} />

        <AppRoute
          path={"/page/:static_page_unique_id"}
          component={StaticPage}
          layout={EmptyLayout}
        />
        <AppRoute
          path={"/sell-item/:contract_address/:token_id"}
          component={ListingItemIndex}
          layout={EmptyLayout}
        />

        <AppRoute
          path={"/all-nfts"}
          component={AllitemsViewer}
          layout={CollectionLayout}
        />

        <AppRoute
          path={"/my-collection/:contract_address"}
          component={EditCollection}
          layout={EmptyLayout}
        />

        <AppRoute
          path={
            "/edit-item/:nft_collection_unique_id/:nft_collection_item_unique_id"
          }
          component={EditNewitem}
          layout={EmptyLayout}
        />

        <AppRoute
          path={"/assets/collection/:contract_address"}
          component={OwnerCollectionViewer}
          layout={CollectionLayout}
        />

        {/* Don't Move this route to top */}
        <AppRoute
          path={"/account/:user_unique_id"}
          component={OtherProfile}
          layout={CollectionLayout}
        />

        <AppRoute path={"*"} component={PageNotFound} layout={EmptyLayout} />
      </Switch>
    </>
  );
};

export default App;
