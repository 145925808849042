import React from "react";

const NoDataFound = () => {
  return (
    <>
      <div className="error-area no-data-found">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="error-content">
              <div className="">
                <img
                  src={
                    window.location.origin + "/assets/images/no-data-new.png"
                  }
                  alt="img"
                />
              </div>
              <h3 className="m-0">No data found</h3>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NoDataFound;
