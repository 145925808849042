import React, { useState, useEffect } from "react";
import CustomCollectionFilter from "../../customComponents/CustomCollectionFilter";
import CustomSelect from "../../customComponents/CustomSelect";
import GridChange from "../../customComponents/GridChange";
import {
  fetchNftItemsListStart,
  loadMoreNftItemsStart,
} from "../../store/actions/NftAction";
import { connect } from "react-redux";
import CustomInfiniteScroll from "../../customComponents/CustomInfiniteScroll";
import CollectionCard from "../../customComponents/CollectionCard";
import CollectionCardLoader from "../loaders/CollectionCardLoader";
import CloseIcon from "@material-ui/icons/Close";

const AllitemsViewer = (props) => {
  const [priceSort, setPriceSort] = useState([]);

  const [gridView, setGridView] = useState(1);

  const [filterStatus, setFilterStatus] = useState(true);

  const [mobileFilterStatus, setMobileFilterStatus] = useState(false);

  //get filter propetries

  const [filterDataContents, setFilterDataContents] = useState({
    sort_by: [],
    min_amount: "",
    max_amount: "",
    categories: [],
    properties: [],
    propertyRemoved: {
      count: 0,
      data: [],
    },
    categoryRemoved: {
      count: 0,
      data: [],
    },
    clearAll: false,
    search_key: "",
  });

  const handlePriceSortChange = (value) => {
    setPriceSort([value]);
    setFilterDataContents({
      ...filterDataContents,
      sort_by: [value],
    });
    if (priceSort.length > 0) {
      props.dispatch(
        fetchNftItemsListStart({
          ...filterDataContents,
          sort_by: priceSort[0].id,
        })
      );
    } else {
      props.dispatch(
        fetchNftItemsListStart({
          ...filterDataContents,
          sort_by: value.id,
        })
      );
    }
  };

  const handleGridViewChange = (value) => {
    setGridView(value);
  };

  const handleFilterDrawerStatus = (status) => {
    setFilterStatus(status);
  };

  const handleMobileFilterDrawerStatus = (status) => {
    setMobileFilterStatus(status);
  };

  useEffect(() => {
    props.dispatch(fetchNftItemsListStart({ take: 20 }));
  }, []);

  useEffect(() => {
    if (Object.keys(filterDataContents).length > 0) {
      if (filterDataContents.sort_by) {
        setPriceSort(filterDataContents.sort_by);
      }
    }
  }, [filterDataContents]);

  const clearFilter = () => {
    setFilterDataContents({
      sort_by: [],
      min_amount: "",
      max_amount: "",
      categories: [],
      properties: [],
      propertyRemoved: {
        count: 0,
        data: [],
      },
      categoryRemoved: {
        count: 0,
        data: [],
      },
      clearAll: true,
      search_key: "",
    });
    setPriceSort([]);
  };

  const removeSingleProperty = (removedPropertyName, removedPropertyValue) => {
    setFilterDataContents({
      ...filterDataContents,
      propertyRemoved: {
        count: filterDataContents.propertyRemoved.count + 1,
        data: { name: removedPropertyName, value: [removedPropertyValue] },
      },
    });
  };

  const removeSingleCategory = (removedCategory) => {
    setFilterDataContents({
      ...filterDataContents,
      categoryRemoved: {
        count: filterDataContents.categoryRemoved.count + 1,
        data: removedCategory,
      },
    });
  };

  const removeSortby = () => {
    setPriceSort([]);
    setFilterDataContents({
      ...filterDataContents,
      sort_by: [],
    });
    props.dispatch(
      fetchNftItemsListStart({
        take: 20,
        sort_by: "",
        min_amount:
          filterDataContents.min_amount > 0
            ? filterDataContents.min_amount
            : "",
        max_amount:
          filterDataContents.max_amount > 0
            ? filterDataContents.max_amount
            : "",
        categories:
          filterDataContents.categories.length > 0
            ? JSON.stringify(filterDataContents.categories)
            : "",
        properties:
          filterDataContents.properties.length > 0
            ? JSON.stringify(filterDataContents.properties)
            : "",
        search_key: filterDataContents.search_key,
      })
    );
  };

  const clearPriceRange = (value) => {
    if (value === "min") {
      setFilterDataContents({
        ...filterDataContents,
        min_amount: "",
      });
      props.dispatch(
        fetchNftItemsListStart({
          take: 20,
          sort_by: priceSort.length > 0 ? priceSort[0].id : "",
          min_amount: "",
          max_amount:
            filterDataContents.max_amount > 0
              ? filterDataContents.max_amount
              : "",
          categories:
            filterDataContents.categories.length > 0
              ? JSON.stringify(filterDataContents.categories)
              : "",
          properties:
            filterDataContents.properties.length > 0
              ? JSON.stringify(filterDataContents.properties)
              : "",
          search_key: filterDataContents.search_key,
        })
      );
    } else if (value === "max") {
      setFilterDataContents({
        ...filterDataContents,
        max_amount: "",
      });
      props.dispatch(
        fetchNftItemsListStart({
          take: 20,
          sort_by: priceSort.length > 0 ? priceSort[0].id : "",
          min_amount:
            filterDataContents.min_amount > 0
              ? filterDataContents.min_amount
              : "",
          max_amount: "",
          categories:
            filterDataContents.categories.length > 0
              ? JSON.stringify(filterDataContents.categories)
              : "",
          properties:
            filterDataContents.properties.length > 0
              ? JSON.stringify(filterDataContents.properties)
              : "",
          search_key: filterDataContents.search_key,
        })
      );
    } else if (value === "minmax") {
      setFilterDataContents({
        ...filterDataContents,
        max_amount: "",
        min_amount: "",
      });
      props.dispatch(
        fetchNftItemsListStart({
          take: 20,
          sort_by: priceSort.length > 0 ? priceSort[0].id : "",
          min_amount: "",
          max_amount: "",
          categories:
            filterDataContents.categories.length > 0
              ? JSON.stringify(filterDataContents.categories)
              : "",
          properties:
            filterDataContents.properties.length > 0
              ? JSON.stringify(filterDataContents.properties)
              : "",
          search_key: filterDataContents.search_key,
        })
      );
    }
  };

  return (
    <>
      <div className="pages-padding all-nfts">
        <div className="collection-items">
          <div className="collection-widget-area">
            <div className="pr-3">
              <div className="filter-drawer-wrapper">
                <CustomCollectionFilter
                  filterStatus={filterStatus}
                  handleFilterDrawerStatus={handleFilterDrawerStatus}
                  mobileFilterStatus={mobileFilterStatus}
                  handleMobileFilterDrawerStatus={
                    handleMobileFilterDrawerStatus
                  }
                  showproperties={false}
                  fetchAction={fetchNftItemsListStart}
                  dispatchData={{
                    sort_by: priceSort.length > 0 ? priceSort[0].id : "",
                  }}
                  setFilterDataContents={setFilterDataContents}
                  filterDataContents={filterDataContents}
                  priceSort={priceSort}
                />
                <div className="p-4">
                  <div className="col-12">
                    <div className="collection-actions">
                      <div className="result-count">
                        {!props.itemsList.loading && (
                          <p>{props.itemsList.data.total} results</p>
                        )}
                      </div>
                      <div className="price-select-wrapper">
                        <CustomSelect
                          value={priceSort}
                          handleChange={handlePriceSortChange}
                          items={[
                            { name: "Newly Added", id: "new" },
                            { name: "Low to high", id: "price_lh" },
                            { name: "High to low", id: "price_hl" },
                            { name: "Buy Now", id: "buy_now" },
                          ]}
                          placeHolder={"Sort by"}
                          idBased
                          nameField={"name"}
                          valueField={"id"}
                        />
                      </div>
                      <div className="grid-view-change">
                        <GridChange
                          value={gridView}
                          onChange={handleGridViewChange}
                        />
                      </div>
                    </div>
                    {Object.keys(filterDataContents).length > 0 && (
                      <>
                        <div className="selected-filters">
                          {filterDataContents.categories && (
                            <>
                              {filterDataContents.categories.map((selected) => (
                                <>
                                  <div className="properties-panel text-center properties">
                                    <p className="mb-0">
                                      <small>category</small>
                                      {selected.name}
                                    </p>
                                    <CloseIcon
                                      onClick={() =>
                                        removeSingleCategory(selected)
                                      }
                                    />
                                  </div>
                                </>
                              ))}
                            </>
                          )}
                          {filterDataContents.sort_by && (
                            <>
                              {filterDataContents.sort_by.map((selected) => (
                                <>
                                  <div className="properties-panel text-center ">
                                    <p className="mb-0">
                                      {selected.name}
                                      <CloseIcon
                                        onClick={() => removeSortby()}
                                      />
                                    </p>
                                  </div>
                                </>
                              ))}
                            </>
                          )}
                          {filterDataContents.min_amount && (
                            <>
                              {Number(filterDataContents.min_amount) > 0 &&
                                Number(filterDataContents.max_amount) == 0 && (
                                  <div className="properties-panel text-center ">
                                    <p className="mb-0">
                                      {`Price > ${filterDataContents.min_amount}`}
                                      <CloseIcon
                                        onClick={() => clearPriceRange("min")}
                                      />
                                    </p>
                                  </div>
                                )}
                            </>
                          )}
                          {filterDataContents.max_amount && (
                            <>
                              {Number(filterDataContents.max_amount) > 0 &&
                                Number(filterDataContents.min_amount) == 0 ? (
                                  <div className="properties-panel text-center ">
                                    <p className="mb-0">
                                      {`Price < ${filterDataContents.max_amount}`}
                                      <CloseIcon
                                        onClick={() => clearPriceRange("max")}
                                      />
                                    </p>
                                  </div>
                                ) : null}
                            </>
                          )}
                          {filterDataContents.max_amount &&
                            filterDataContents.min_amount && (
                              <>
                                {Number(filterDataContents.max_amount) > 0 &&
                                  Number(filterDataContents.min_amount) ? (
                                    <div className="properties-panel text-center ">
                                      <p className="mb-0">
                                        {`Price ${filterDataContents.min_amount} - ${filterDataContents.max_amount}`}
                                        <CloseIcon
                                          onClick={() =>
                                            clearPriceRange("minmax")
                                          }
                                        />
                                      </p>
                                    </div>
                                  ) : null}
                              </>
                            )}
                          {filterDataContents.isActive && (
                            <button
                              className="mb-0 link-btn"
                              onClick={() => clearFilter()}
                            >
                              Clear All
                            </button>
                          )}
                        </div>
                      </>
                    )}
                  </div>

                  {props.itemsList.loading ? (
                    <>
                      <div
                        className={`collection-grid no-margin ${
                          gridView != 1 ? "small" : ""
                        }`}
                      >
                        <CollectionCardLoader
                          count={20}
                          classes={`mt-3 ${gridView != 1 ? "small" : ""}`}
                        />
                      </div>
                    </>
                  ) : (
                    props.itemsList.data.nft_collection_items.length > 0 && (
                      <div>
                        <CustomInfiniteScroll
                          fetchAction={loadMoreNftItemsStart}
                          fetchData={props.itemsList}
                          fetchedData={
                            props.itemsList.data.nft_collection_items
                          }
                          skip={
                            props.itemsList.data.nft_collection_items.length
                          }
                          take={20}
                          skippedFetch={true}
                          total={props.itemsList.data.total}
                          useLoader={true}
                          loaderComponent={
                            <CollectionCardLoader count={12} classes="mt-3" />
                          }
                          loaderclasses={`collection-grid no-margin ${
                            gridView != 1 ? "small" : ""
                          }`}
                          dispatchData={{
                            min_amount: filterDataContents.min_amount,
                            max_amount: filterDataContents.max_amount,
                            categories:
                              filterDataContents.categories.length > 0
                                ? JSON.stringify(filterDataContents.categories)
                                : "",
                            properties:
                              filterDataContents.properties.length > 0
                                ? JSON.stringify(filterDataContents.properties)
                                : "",
                            search_key: filterDataContents.search_key,
                            sort_by:
                              priceSort.length > 0 ? priceSort[0].id : "",
                          }}
                        >
                          <div
                            className={`collection-grid no-margin ${
                              gridView != 1 ? "small" : ""
                            }`}
                          >
                            {props.itemsList.data.nft_collection_items.map(
                              (item, index) => (
                                <CollectionCard
                                  data={item}
                                  useFlex={false}
                                  key={index}
                                  gridView={gridView}
                                />
                              )
                            )}
                          </div>
                        </CustomInfiniteScroll>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  itemsList: state.nfts.itemsList,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(AllitemsViewer);
